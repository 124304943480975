import { admin2 } from '@/api/instance'

export const RegisterShopRebate = async ({
  shopId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopPoint/register`,
  })
}

export const FindShopPoint = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPoint`,
  })
}

export const UpdateShopPoint = async ({
  shopId,
  name,
  unitName,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopPoint`,
    data: {
      name,
      unitName,
    },
  })
}

export const GetShopPointYearExpiration = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPoint/yearExpiration`,
  })
}

export const UpdateShopPointYearExpiration = async ({
  shopId,
  daysUntilExpiration,
  daysDiffExpirationNotify,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopPoint/yearExpiration`,
    data: {
      daysUntilExpiration,
      daysDiffExpirationNotify,
    },
  })
}

export const FindMemberPointYearExpirations = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/member/${memberId}/pointWalletYearExpirations`,
  })
}

export const AddPointWalletRecord = async ({
  shopId,
  memberId,
  addAmount,
  originMessage,
  branchId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/member/${memberId}/addPointWalletRecord`,
    data: {
      addAmount,
      originMessage,
      branchId,
    },
  })
}

export const UsePointWalletRecord = async ({
  shopId,
  memberId,
  useAmount,
  originMessage,
  branchId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/member/${memberId}/usePointWalletRecord`,
    data: {
      useAmount,
      originMessage,
      branchId,
    },
  })
}

export const FindPointWalletRecord = async ({
  shopId,
  memberId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/member/${memberId}/pointWalletRecord`,
    params: {
      start,
      limit,
    },
  })
}

export const FindPointWalletRecordCount = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/member/${memberId}/pointWalletRecord/count`,
  })
}

export const GetOriginTotalAmount = async ({
  shopId,
  createStart,
  createEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/originTotalAmount`,
    params: {
      createStart,
      createEnd,
    },
  })
}

export const GetOriginTotalAmountByBranch = async ({
  shopId,
  createStart,
  createEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/originTotalAmountByBranch`,
    params: {
      createStart,
      createEnd,
    },
  })
}

// report
export const GetPointRecord = async ({
  shopId,
  createStart,
  createEnd,
  start,
  limit,
  origin,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/records`,
    params: {
      createStart,
      createEnd,
      start,
      limit,
      origin,
      memberId,
    },
  })
}

export const GetPointRecordCount = async ({
  shopId,
  createStart,
  createEnd,
  origin,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointReport/records/count`,
    params: {
      createStart,
      createEnd,
      origin,
      memberId,
    },
  })
}

// 新增給點連結活動
export const CreateShopPointLink = async ({
  shopId,
  name,
  hasEffectivePeriod,
  point,
  description,
  startAt,
  endAt,
  imageId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopPointLink`,
    data: {
      name,
      hasEffectivePeriod,
      point,
      description,
      startAt,
      endAt,
      imageId,
    },
  })
}
// 取得點數連結活動列表
export const GetShopPointLink = async ({
  shopId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointLink`,
    params: {
      start,
      limit,
    },
  })
}
// 取得點數連結活動數量
export const GetShopPointLinkCount = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointLink/count`,
  })
}
// 取得點數連結活動
export const FindShopPointLink = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointLink/${id}`,
  })
}
// 更新點數連結活動
export const UpdateShopPointLink = async ({
  shopId,
  id,
  name,
  hasEffectivePeriod,
  description,
  startAt,
  endAt,
  imageId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopPointLink/${id}`,
    data: {
      name,
      hasEffectivePeriod,
      description,
      startAt,
      endAt,
      imageId,
    },
  })
}
// 刪除點數連結活動
export const DeleteShopPointLink = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/shopPointLink/${id}`,
  })
}
// 取得點數連結序號列表
export const GetShopPointLinkCode = async ({
  shopId,
  ShopPointLinkId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointLinkCode`,
    params: {
      start,
      limit,
      ShopPointLinkId,
    },
  })
}
// 取得點數連結序號數量
export const GetShopPointLinkCodeCount = async ({
  shopId,
  ShopPointLinkId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopPointLinkCode/count`,
    params: {
      ShopPointLinkId,
    },
  })
}
// 批次新增給點連結活動
export const CreateShopPointLinkCode = async ({
  shopId,
  shopPointLinkId,
  amount,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopPointLinkCode/batchCreate`,
    data: {
      shopPointLinkId,
      amount,
    },
  })
}
